<template>
  <div class="card-image" style="max-width: 1280px">
    <div v-if="loading">
      <p>Loading livestream...</p>
    </div>

    <template v-if="wsPlayer">
      <div id="player"></div>
    </template>
    <template v-else>
      <div class="py-5">
        <b-notification type="is-warning is-light">For the best results and lowest delay, please use a Chrome based browser.
        </b-notification>
      </div>
      <vue-plyr ref="plyr" :options="playerOptions">
        <video  :id="'video-1'" data-plyr-config="{'autoplay': true}">
        </video>
      </vue-plyr>
    </template>

    <div class="pt-5">
      <b-progress v-if="loading" size="is-small"></b-progress>
      <b-progress v-else :value="formattedTimeLeft" :max="timeMax" size="is-small"></b-progress>
    </div>

    <div class="pt-5">
      <b-notification type="is-dark">Quality of the Live-Streams is depending on the local mobile coverage and bandwidth, this doesn't
        involve the recorded images.
      </b-notification>
    </div>
  </div>

</template>

<script>
import packages from "@/store/modules/packages.store"
import RefreshButton from "../../RefreshButton";
import CardComponent from "../../CardComponent";
import VuePlyr from 'vue-plyr';
import Hls from 'hls.js';
import 'vue-plyr/dist/vue-plyr.css'

export default {
  name: "CameraStreamComponent",
  components: { CardComponent, RefreshButton, VuePlyr },
  props: ['camera_id', 'project_id', 'name'],

  data() {
    return {
      loading: true,
      url: null,
      synced_at: null,
      timeLeft: null,
      timeMax: null,
      timerInterval: null,
      wsPlayer: true,
      sldpPlayer: null,

      playerOptions: {
        controls: ['play', 'airplay', 'current-time', 'mute', 'volume', 'settings', 'fullscreen'],
        settings: ['quality', 'speed', 'loop'],
        fullscreen: { enabled: true, fallback: true, iosNative: true, container: null },
        autoplay: true,
        autopause: false,
        debug: false,
      }
    }
  },
  computed: {
    formattedTimeLeft() {
      return this.timeMax - this.timeLeft
    },
    player() {
      return this.$refs.plyr.player;
    },
  },
  created() {
    if (!packages.isRegistered) {
      this.$store.registerModule('packages', packages)
      packages.isRegistered = true
    }


  },
  mounted() {
    // Use HLS when it is a Safarild browser, issues with WS
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.wsPlayer = !isSafari

    this.fetchData()

  },

  beforeDestroy() {
   this.stopStream()
  },

  methods: {

    fetchData() {
      this.$store.dispatch('packages/fetchCameraStream', {project_id: this.project_id, camera_id: this.camera_id})
          .then(response => {
            if (response.success) {
              this.url = response.url;
              this.url_hls = response.url_hls
              this.timeLeft = response.duration;
              this.timeMax = response.duration;

              if (this.wsPlayer) {

                this.sldpPlayer = SLDP.init({
                  container: 'player',
                  stream_url: this.url,
                  buffering:          400,
                  adaptive_bitrate:   false,
                  latency_tolerance: 500,
                  muteable: true,
                  autoplay:           true,
                  key_frame_alignment: false,
                  width: "parent",
                  height: "parent"

                });
                this.sldpPlayer.setCallbacks({
                  onConnectionStarted: this.onPlay(),
                })
                return

              } else if (!Hls.isSupported()) {
                console.log("nosupport!")
                this.player.media.src = this.url;

              } else {
                // For more Hls.js options, see https://github.com/dailymotion/hls.js
                const hls = new Hls();
                hls.loadSource(this.url_hls);
                hls.attachMedia(this.player.media);
                window.hls = hls;

                // Handle changing captions
                this.player.on('languagechange', () => {
                  // Caption support is still flaky. See: https://github.com/sampotts/plyr/issues/994
                  setTimeout(() => hls.subtitleTrack = player.currentTrack, 50);
                });

                hls.on(Hls.Events.ERROR, function (event, data) {
                  if (data.fatal) {
                    switch (data.type) {
                      case Hls.ErrorTypes.NETWORK_ERROR:
                        // try to recover network error
                        console.log('fatal network error encountered, try to recover');
                        hls.startLoad();
                        break;
                      case Hls.ErrorTypes.MEDIA_ERROR:
                        console.log('fatal media error encountered, try to recover');
                        hls.recoverMediaError();
                        break;
                      default:
                        // cannot recover
                        hls.destroy();
                        break;
                    }
                  }
                });
              }

              this.player.on('play', () => this.onPlay())
              this.player.on('stalled', () => {
                console.log('stalled fired')
                if(!this.loading) {
                  this.player.play()
                }
              })
              this.player.on('error', () => {
                console.log('error fired')
                this.player.play()
              })

            } else {
              this.$buefy.toast.open({
                message: response.message,
                type: 'is-danger'
              })
              this.loading = false
              this.$parent.close()
            }

          })
    },
    onPlay() {
      if (this.loading) {
        this.startTimer()
      }
      this.loading = false

    },
    stopStream() {
      if(this.wsPlayer) {
        this.sldpPlayer.destroy();
      } else {
        this.player.stop()
        this.player.destroy()
        window.hls.destroy()
      }
      this.$parent.close()
    },
    startTimer() {
      this.timerInterval = setInterval(() => (this.timeLeft -= 1), 1000);
      let thisIns = this;
      setTimeout(function () {
        thisIns.stopStream()
      }, 1000 * this.timeMax)
    },

  },

}
</script>

<style scoped>



</style>
